@use "../../theme.scss" as theme;

.Companies {
  @include theme.flex-row-centered;
  @include theme.small-screen {

    .CompanyContainer {

      height: 10rem;
      justify-content: center;

      & img { // logos
        margin: 30% 0;
        height: auto;
        width: 100%;
      }
      
      &:nth-of-type(2) { // middle company (airvance)
        & img { // airvance logo - used to vertically align it, since logos are sized differently (square for accor and cdc, and large for airvance), aligning them smartly using flexbox is pretty tough. 
          margin: 30% 0;
        }
      }
      
    }

    .Tagline {
        font-size: 1rem;
        margin: 0 0 1.5rem 0;
    }
  }
}

.CompanyContainer {
  @include theme.flex-column-centered;
  justify-content: center;
  height: 10rem; // fixed height to prevent content below to move depending on which company is selected
  
  margin: 5% auto 10% auto;
  width: 33.3%;
  opacity: 0.5;
  transition: opacity 0.15s ease-in-out, transform 0.75s ease-in-out;
  
  transform: scale(0.8);
  &.selected {
    opacity: 1;
    transform: scale(1)
  }
  &.selected, &:hover {
    opacity: 1;
    transform: scale(0.9)
  }
  & img {
    height: auto;
    width: 33%;
    margin: 5% auto;
  }
}


.CompanyLogoContainer {
  height: 40%;
  width: auto;
}

.Tagline {
  @include theme.gradient-text-color(theme.$orange-to-yellow-gradient);
  width: 150%; // larger so that line break does not happen, preventing taglines to not be aligned 
  align-self: center;
  font-size: 1.6rem;
  transition: all 1s ease-in-out;
  display: none; // so that company name is centered when tagline is not visible
  text-align: center;
  font-style: italic;
  
  &.selected {
    display: inline-block; // to display quote when selected
    transition: all 1s ease-in-out;
  }
}
 

.OneBubble {
  color: theme.$white;
  font-size: 1.15rem;
  font-weight: 300;
  line-height: 1.85rem;
  border: 2px solid theme.$white;
  border-radius: 50%;
  background: transparent;
  padding: max(0.6vh, 16px) max(1vw, 20px); // Responsive uniform inner bubble padding
  border-radius: 2em;
  margin: max(20px, 3vh) max(10px, 1vw);
  max-width: min(1000px, 95vw);
  text-justify: inter-character; // not supported by many browsers yet, maybe implement later and design differently for now?

  /* De-value the bubbles that are not selected on mobile */
  @include theme.medium-screen {
    opacity: 0.5;
    &.selected {
        opacity: 1;
      }

      &:not(.selected) { // so that a hand appears to indicate that we need to click to see more :)
        cursor: pointer;
      }
    }
}

.AllBubbles {
  @include theme.flex-column-centered;
}

.Text {
  @include theme.flex-column-centered;
  margin-top: max(20px, 1vh);
  overflow-y: auto;

  li {
    text-align: left;
    list-style: none;
  }

  & > strong {
    display: contents;
    @include theme.bold-Montserrat;
  }
  
  @include theme.medium-screen {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    
    .selected & {
      -webkit-line-clamp: unset;
    }
  }
}

.BubbleContent {
  padding: max(3.5%, 1.6vh) max(3.5%, 0.6vw); // Responsive uniform inner bubble padding
  display: inline-block;
  text-align: left;
}
    
.Header {
  @include theme.title;
  font-size: 2.9rem;
  font-weight: 800;
  text-align: left;
  padding-left: 3rem;
}

.ClientIdentity {

  @include theme.cursive;
  font-size: 1.4rem;
  font-weight: 900;
  opacity: 0.7;
  opacity: 1;
  text-align: right;
  margin-top: max(1.2rem, 2.5vh);
}

.ClientPosition {
  text-align: right;
  font-size: 0.8em;
  font-style: italic;
}