@use "../theme.scss" as theme;

.RendezVousParent {
    display: flex;
    max-width: 100vw;
    height: 880px;
    @media (max-width: 1100px) {
        height: 1300px;
    }
    @media (max-width: 715px) {
        height: 1200px;
    }
    @media (max-width: theme.$smallScreenBreakpoint) {
        height: 1200px;
    }
}

:global(.calendly-inline-widget) {
    width: 100vw !important;
    height: 100% !important;
    overflow-y: hidden !important;
}

.Content {
    margin: max(10%, 50px) max(4%, 15px);
    font-size: 1.8rem;
    line-height: 2.9rem;
    text-align: left;

    & > strong {
        color: theme.$secondary-dark;
    }
    & > em {
        color: theme.$primary-dark;
        font-style: normal;
        @include theme.bold-Montserrat;
    }
}