@use "../theme.scss" as theme;

.MentionsLegales {
	min-height: 100vh;
	width: 100%;
}

.Container {
    @include theme.flex-column-centered;
    justify-content: space-around;
	@include theme.text;
	font-size: 1.5rem;
	line-height: 2rem;
	min-height: 100vh;
    padding: 10% 0;
}

.Content {
    margin: 0 5vw;
    max-width: 1200px;
}

.Section {
    padding-bottom: 2rem;
    p {
        text-align: left;
    }

    h4 { // section title
        @include theme.title;
        padding: 5%;
    }
}

