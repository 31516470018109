@use './../theme.scss' as theme;

.Parent {
    @include theme.flex-column-centered;
    margin: max(5%, 50px) auto;
    max-width: 100%;
}

.Shape {
    position: relative;
    background-image: url('./../static/img/forme.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 50vh;
    max-height: 500px;
    
    & > h3 {
        position: relative;
        font-size: 4rem;
        z-index: 5;
        text-shadow: -0.2rem 0.2rem theme.$primary-dark, 0.2rem -0.2rem theme.$tertiary-dark;
        top: 30%;
        width: fit-content; // to prevent line-breaks
        min-width: 70vw;
        max-width: 600px;
        animation: moving-title 20s linear infinite;
    }
}

@keyframes moving-title {
    0%, 40% {
        transform: translateX(0px) translateY(20px) scale(0.95);
    }
    10%, 50%, 80% {
        transform: translateX(-10px) translateY(0px) scale(1);
    }
    20%, 60%, 90% {
        transform: translateX(0px) translateY(-20px) scale(0.90);
    }
    30%, 70%, 100% {
        transform: translateX(10px) translateY(20px) scale(1);
    }
}