@use './../theme.scss' as theme;

.FormPageParent {
    @include theme.flex-column-centered;
    margin: max(5%, 50px) auto;
    max-width: theme.$contentMaxWidth;
}

.DescriptionText {
	width: min(24rem, 60vw);
    margin: 2vh 1vw;
}