@use "../theme.scss" as theme;

.Block {
	min-height: 70vh;
	@include theme.flex-column-centered;
	justify-content: space-around;
	h6 {
		@include theme.text;
		font-size: 1rem;
	}
}

.Shape {
	letter-spacing: 1px;
	text-align: center;
	text-transform: capitalize;
	
	& > h6 {
		position: relative;
		z-index: 5;
		top: -89px;
	}
}

.BlockTitle {
	padding-top: theme.$navBarHeight;
	padding-bottom: 2vh;
	width: 100%;
	@include theme.StickyContainer(0, 999);
}

h3 {
	margin: 2vh auto 5vh auto;
	max-width: theme.$contentMaxWidth;
}

$bgGradient : linear-gradient(180deg, #090b1a 0%, 
theme.background-alpha(0.95) 36%,  
theme.background-alpha(0.7) 85%,  
theme.background-alpha(0) 100%);

 :global(.will-stick) {
	 position: sticky;
	 top: 0;
	 height: 25vh !important;
	 
	 @include theme.medium-screen {
		 height: 30vh !important; // on small screens, title is on 2 lines so height need to be bigger
	 }
	 width: 100vw;
	 opacity: 0;
	 z-index: 2;
	 background-image: $bgGradient;
	}
	
	:global(.stuck) {
	transition: opacity 2s ease-out;
	opacity: 1;
}