@use "./../../theme.scss" as theme;
@use "sass:math";

$navbarDarkblue: theme.background-alpha(1);
$navbarLight: theme.background-alpha(0.7);
$navbarSemiTransparent: theme.background-alpha(0.5);
$navbarTransparent: theme.background-alpha(0);
$navbarBackground: linear-gradient(
	180deg,
	$navbarDarkblue 0%,
	$navbarLight 33%,
	$navbarSemiTransparent 66%,
	$navbarTransparent 100%
);
$navbarLightBackground: linear-gradient(
	180deg,
	white 40%,
	white 50% 65%,
	rgba(255, 255, 255, 0) 100%
);
$navbarSideMargin: 3vw;

$hamburgerWidth: 7vw;
$hamburgerHeight: 7vw;

.NavBackground {
	position: sticky;
	top: 0;
	height: theme.$navBarHeight;
	background-image: $navbarBackground;
	z-index: 1000;
	width: 100%;
	max-width: 100vw;
	transition: 0.25s all ease-in-out;
	// :global(.light-mode) & {
	// 	background-image: $navbarLightBackground;
	// }
	pointer-events: none;
}

.NavBar {
	@include theme.title;
	@include theme.flex-row;
	height: inherit;
	align-items: flex-start;
	justify-content: flex-end;
	padding: 0 $navbarSideMargin;
	overflow-y: visible;
	pointer-events: all;

	& a {
		color: theme.$white;
	}

	& > * {
		height: theme.$navBarHeight;
		display: flex;
		align-items: center;
	}
}

.DesktopNavLinks {
	@include theme.flex-row-centered;
	justify-content: space-around; // better than space-between to reduce gap between items on wide screens
	width: 80%;
	max-width: 1400px;
	height: 100%;

	& > a {
		text-align: center;
		// :global(.light-mode) & {
		// 	color: theme.$black;
		// }
	}
	@include theme.medium-screen {
		display: none;
	}
}

.MobileNavLinks {
	transition: all 0.35s;
	@include theme.flex-column-centered;
	align-items: flex-end;
	padding-right: max(90px, 20vw);
	padding-top: math.div(theme.$navBarHeight, 2);
	justify-content: flex-start;

	gap: 6vh;
	position: absolute;
	opacity: 0;
	transform: translate(-100vw);
	height: 100vh;
	width: 100vw;
	background-color: theme.background-alpha(0.8);
	backdrop-filter: blur(5px) brightness(0.6);
	& > a {
		font-size: 1.8rem;
	}

	&.open {
		opacity: 1;
		transform: translate($hamburgerWidth + $navbarSideMargin);
	}
}

.Logo {
	margin-right: auto; // needed to override the flex justify: flex-end; of NavBar
	// :global(.light-mode) & {
	// 	filter: invert(1);
	// }
}

.HamburgerMenu {
	display: none;
	transition: transform 0.5s;
	height: $hamburgerHeight;
	min-height: 20px;
	max-height: 40px;
	width: auto;
	// position: relative;
	// right: max(5vw, 50px);
	margin-right: max(5vw, 15px);
	@include theme.medium-screen {
		display: unset;
		align-self: center;
		// position: relative;
		// top: 1vh;
		// right: max(3vw, 15px);
		// padding: 0 max(6vw, 15px);
	}
}

.NavLink {
	text-decoration: none;
	@include theme.text;
	font-size: 1.2rem;
	@include theme.bold-Montserrat;
	& > * {
		padding: inherit;
		margin: 0;
		padding: 0;

	}
	&:hover {
		color: theme.$secondary-dark;
		text-decoration: none;
	}
}

.NavCta {
	padding: theme.$buttonsPadding;
}