@use "./../theme.scss" as theme;

.Container {
	@include theme.flex-responsive-wrap;
	margin: 0 auto;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	
	// max-width: min(1200px, 95vw);
	max-width: 100%;

	ul {
		height: 100%;
		padding-left: 0; //undo browser default
		width: 43%;
	}
	
	li.selected {
		@include theme.gradient-text-color(theme.$orange-to-yellow-gradient);
		font-weight: 700;
		font-size: 1.1rem;
		transform: scale(1.25);
	}
	
	li {
		transform-origin: left;
		transition: all 0.5s;
		list-style: none;
		height: 24%;
		transform: scale(1);
		max-width: 100%;
		width: 80%;
	}


	@include theme.medium-screen {
		ul {
			width: 100%;
		}

		li:not(.selected) {
			/* Hide non-active features on mobile */
		}
	}
}

@mixin StackingContainer {
	position: relative;
}

.Stacked {
	position: absolute;
	top: 0;
	left: 0;
}

.Anim {
	@include StackingContainer();
	height: 50vh;
	max-height: 600px;
	width: 100%;
	max-width: 600px;
	z-index: 0;
	
	&.Mobile {
		display: none;
		margin: 0 auto;
	}
	&.Desktop {
		max-width: 45%;
		max-height: 45%;
	}
	@include theme.medium-screen {
		&.Mobile {
			// width: 90vw;
			display: block;
		}
		&.Desktop {
			display: none;
		}
	}
}


.Feature {
	@include theme.highlight-and-grow-on-hover;
	line-height: 1.5rem;
	font-size: 1.5rem;
	width: 20%;
	& > img {
		width: 100%;
	}
}

.FeatureTitle {
	margin: 1rem auto;
} 
