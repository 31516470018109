@use './../../theme.scss' as theme;

.MaskContainer {
	position: fixed;
	height: 100vh;
	width: 100vw;
	mask-image: theme.$mask-image;
}

.HeroParent {
	@include theme.flex-column-centered;
	width: 100%;
	max-width: theme.$contentMaxWidth;
	margin: 5vh auto;
	position: relative;
	top: 0;
	@include theme.extra-large-screen {
		width: 1700px;
	}
}

.TextContainer {
	@include theme.flex-column-left;
	max-width: 92vw;
	margin: 50px 0;
	z-index: 1;

	& > * {
		// to make words align vertically on smaller devices
		@include theme.small-screen {
			text-align: center;
			left: 0;
			top: 0;
		}
	}
}

.GraceA {
	@include theme.cursive;
	align-self: center;
	opacity: 0.7;
	font-size: 2.5rem; // equals 40px;
	padding: 10px;
}

.TextLeft {
	font-size: 2.8rem;
	@include theme.bold-Montserrat;
	margin-right: 1rem; // used to artificially make container larger, so that it doesn't grow bigger (and move) when longer words are visible
}

.TextRight {
	width: 100%;
	align-self: flex-start;
	font-size: 3rem;
}

.Plus {
	margin-right: 0.5rem;
}

.HeroWordsOrganisations {
	@include theme.gradient-text-color(theme.$pink-to-yellow-gradient);
	@include theme.bold-gradient-text;
}

.HeroWordsCollaborateurs {
	@include theme.gradient-text-color(theme.$tertiary-to-primary-horizontal);
	@include theme.bold-gradient-text;
}

.Form > * {
	@include theme.flex-column-centered;
}

.FormContainer {
	@include theme.flex-column-centered;
	margin: 5vh auto;
	@include theme.small-screen {
		@include theme.flex-column-centered;
		flex-wrap: wrap;
	}
}

.LogoAnimContainer {
	@include theme.flex-column-centered;
	position: relative;
	right: 0px;
	margin-top: 20px;
	max-width: 20rem;
	justify-self: center;
}

.LogoAnim {
	@include theme.flex-column-centered;
	z-index: 0;
	width: 100%;
}

.TextAndLogo {
	@include theme.flex-responsive-wrap;
	justify-content: space-around;
	position: relative;
	top: 0;
	left: 0;
	width: 100%; // on small screens, take up all the parent width
	max-width: 1000px; // on big screens, don't leave too much space between logo and text
}

.DescriptionText {
	@include theme.text;
	align-self: flex-start;
	font-size: 0.9rem;
	margin: 2rem;
	@include theme.medium-screen {
		@include theme.flex-column-centered;
		align-self: center;
		text-align: center;
		max-width: min(100%, 90vw);
	}
}

// SMALL SCREENS
@include theme.small-screen {
	.TextRight {
		@include theme.flex_column_centered;
	}
}

.HeroVideoContainer {
	max-width: 890px;
	max-height: 500px;
}
