@use "./../theme.scss" as theme;

$ready-to-go-font-size: 6rem;
$elementPadding: 1.5rem;

.FooterParent {
	@include theme.flex-column;
	justify-content: space-evenly;
	align-items: center;
	width: 100vw;
	border-top: 3px solid theme.$white; // used to draw a line on top of the footer
	padding-top: min(6vh, 40px);
	margin-top: min(8vh, 60px);
	& > * {
		margin: 1.1vh auto;
	}

	.ReadyToGoFullContainer {
		@include theme.flex-row-centered;
		width: 80%;
		max-width: 600px;
		padding: 2rem auto;

		.Subtitle {
			// "Accompagnez la transformation de vos équipes" text
			@include theme.title;
			font-size: 1.6rem;
			letter-spacing: 0.2rem;
			line-height: 1.5rem;
			text-align: right;
			order: 1;
			padding-right: 5rem; // uniform distance around the white line
			line-height: 1.9rem;
		}

		.ReadyToGoContainer {
			@include theme.flex-column-left;
			align-items: flex-start;
			justify-content: flex-start;
			border-left: solid 1px theme.$white;
			padding-left: 5rem; // uniform distance around the white line
			padding-top: $elementPadding;
			padding-bottom: $elementPadding;
			order: 2;
			& > * {
				margin: 1rem 0 0 0;
			}
		}

		.ToGoContainer {
			@include theme.flex-row-centered;
		}

		.Ready,
		.To,
		.Question {
			@include theme.title;
			font-size: $ready-to-go-font-size;
		}

		.To,
		.Question {
			padding-top: $elementPadding;
		}
		.To {
			padding-right: $elementPadding;
		}
		.Question {
			padding-left: $elementPadding;
		}

		.ImageLetter {
			height: 4.7rem;
			margin: 1rem 0 0 0;
		}

		@include theme.small-screen {
			&.ReadyToGoFullContainer {
				@include theme.flex-column-centered;
				padding: 0;
				margin: 0;
			}

			&.ReadyToGoContainer {
				@include theme.flex-column-centered;
			}

			.Ready {
				margin: 0 auto;
			}

			.To,
			.Question {
				padding: 0;
				padding-top: $elementPadding;
			}

			.To {
				padding-right: $elementPadding;
			}
			.Question {
				padding-left: $elementPadding;
			}

			.ReadyToGoContainer {
				padding: $elementPadding 0;
				order: 1;
				border-bottom: 1px solid theme.$white;
				border-left: none;
			}

			.Subtitle {
				width: 45%;
				padding: $elementPadding 0 0 0;
				order: 2;
				text-align: center;
			}
		}
	}

	.Logo {
		min-width: 60px;
		width: 2vw;
		max-width: 150px;
	}

	.Visigo {
		padding-top: 0.9rem; // articifially lower logo so it seems centered, because its "g" makes it look like it is not
	}

	.LogosContainer {
		@include theme.flex-row-centered;
		a {
			margin: 1.1rem 2rem 1rem 2rem;
		}
	}

	.FooterLinks {
		@include theme.flex-row-centered;

		& > * {
			text-decoration: none;
			margin: 0 3rem;
		}
	}

	@include theme.small-screen {
		.FooterLinks {
			@include theme.flex-column-centered;
			& > * {
				margin: 1rem 0;
			}
		}

		.LogosContainer {
			@include theme.flex-column-centered;
		}
	}

	// To separate items from each other
	.FooterLinks > *,
	.SocialLinks > *,
	.MadeWithLove > *,
	.LogosContainer > *,
	.Copyright {
		padding: 0 min(12px, 1rem);
	}

	.MadeWithLove {
		font-size: 0.8rem;
		text-align: center;
		padding: min(1vh, 10px);
	}

	// .SocialLinks {
	// 	text-align: left;
	// @include theme.flex-row-centered;
	// padding: 20px 0;
	// }

	.Copyright {
		@include theme.flex-column-centered;
		background: theme.$white;
		color: theme.$tertiary-darkblue;
		font-size: 0.8rem;
		width: 100vw !important;
		max-width: 100vw;
		height: min(7vh, 50px);
		margin: 0;
		padding: 0;
	}

	.SocialLink {
		min-width: none;
		padding: 0 20px;
	}

	.FooterLinks a {
		&:hover {
			@include theme.gradient-text-color(theme.$orange-to-yellow-gradient);
		}
	}
}
