@use "./../theme.scss" as theme;

.Price {
	@include theme.flex-column-centered;
	text-align: center;
	max-width: theme.$contentMaxWidth;
	margin: 0 auto;
	height: 30vh;
}

.GradientTextCursiveQuarternary {
	@include theme.gradient-text-cursive;
	background: theme.$tertiary-to-quarternary-horizontal;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}
