@use "../theme.scss" as theme;

.DecideursParent {
    @include theme.flex-column-centered;
}

.Video { 
    width: 94vw;
    height: 86vh;
    margin: 0;
}

.Content {
    margin: max(10%, 50px) max(4%, 15px);
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2.9rem;
    text-align: left;

    & > strong {
        color: theme.$secondary-dark;
    }
    & > em {
        color: theme.$primary-dark;
        font-style: normal;
        @include theme.bold-Montserrat;
    }
}