@use "./../theme.scss" as theme;


$circlePositions : 0% 8%, 
                  90% 20%,
                  5% 30%, 
                  90% 40%,
                  10% 80%,
                  90% 90%,
                  10% 60%;

$circleColors : rgba(255, 51, 75, 0.35), 
          rgba(159, 116, 251, 0.314), 
            rgba(87, 153, 235, 0.83), 
            rgba(31, 221, 106, 0.145), 
            rgba(31, 221, 106, .3), 
            rgba(255, 123, 49, 0.502), 
            rgba(87, 199, 235, 0.502), 
            rgba(159, 116, 251, 0.502), 
            rgba(255, 123, 49, 0.502), 
            rgba(214, 37, 173, 0.502);

$circleSizes : 500px, 400px, 450px, 500px, 600px, 700px, 800px;

$numCircles : 7;

$backgroundCircles : ();


@function makeCircle($size, $position, $color1, $color2) {
  $madeCircle: radial-gradient(circle $size at $position, $color1, $color2);
  @return $madeCircle;
}


@for $i from 1 through $numCircles {
  $newCircle : makeCircle(
    nth($circleSizes, $i),
    nth($circlePositions, $i),
    nth($circleColors, $i) 0%,
  rgba(0,0,0,0) 100%);
  $backgroundCircles: append($backgroundCircles, $newCircle, comma);
}


// $circle1 : radial-gradient(circle 200px at $position1, #FF324A80 22.56%, rgba(255, 50, 74, 0) 83%);
// $circle2 : radial-gradient(circle 300px at $position2, #9F74FB50 0%, rgba(23, 105, 210, 0) 80%);
// $circle3 : radial-gradient(circle 300px at 10% 10%, rgba(87, 153, 235, 0.83) 5.83%, rgba(31, 221, 106, 0) 84.55%);
// // $circle2 : radial-gradient(circle 600px at 80% 20%, #1FDD6A25 0%, rgba(23, 105, 210, 0) 80%);
// // $circle3 : radial-gradient(circle 200px at 20% 40%, rgba(31, 221, 106, .3) 0%,rgba(87, 153, 235, 0) 100%);
// $circle4 : radial-gradient(circle 200px at 70% 50%, #FF7B3180 0%, #FF324A00 100%);
// $circle6 : radial-gradient(circle 200px at 80% 80%, #57C7EB80 4.97%, rgba(255, 255, 255, 0) 91.86%);
// // $circle7 : radial-gradient(180deg, #9F74FB80 0%, rgba(23, 105, 210, 0) 100%);
// // $circle8 : radial-gradient(180deg, #FF7B3180 0%, rgba(203, 119, 85, 0.775908) 22.41%, rgba(23, 105, 210, 0) 100%);
// // $circle9 : radial-gradient(180deg, #D625AD80 0%, rgba(23, 105, 210, 0) 100%);

@mixin background {
  height: 100%;
  width: 100%;
}

@mixin dark-background {
  @include background;
  backdrop-filter: blur(200px);
  background-image: 
  $backgroundCircles,
  theme.$backgroundGradient;
}

.Background {
  color: theme.$white;
  width: 100%;
}

.DarkBackground {
  @include dark-background;
}

.Circle {
  position: absolute;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background: linear-gradient(circle, rgba(87, 153, 235, 0) 5.83%, rgba(31, 221, 106, 0.83) 84.55%);
  filter: blur(50px);
  opacity: 0.3;
}

.Big {
  height: 55%;
  width: 55%;
}

.Medium {
  height: 35%;
  width: 35%;
}

.Small {
  height: 15%;
  width: 15%;
}
