@use "./../theme.scss" as theme;

.NotFoundParent {
    @include theme.flex-column-centered;
    max-width: theme.$contentMaxWidth;
    min-height: 100vh;
    justify-content: flex-start;
    
    p, a {
        font-size: 1.5rem;
        margin: 2rem auto;
    }
}