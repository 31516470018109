@use './../theme.scss';

.Quote {
	@include theme.text;
	display: flex;
	flex-direction: column;
	align-self: auto;
	font-size: 16px;

	position: relative;
	top: 0;
	right: 0;
	font-weight: 300;
	border: 3px solid theme.$white;
	justify-self: center;
	justify-content: center;
	text-align: left;
	padding: 2.5em;
	box-shadow: 0 7px 7px theme.$light-grey;
	height: fit-content;
	overflow-y: visible;
	width: 280px;
	max-width: 900px;

	& strong {
		font-weight: 600;
	}
}

.LeftCorner {
	border-radius: 30px 30px 30px 0;
}

.RightCorner {
	border-radius: 30px 30px 0 30px;
}

.QuoteStyle1 {
	background: theme.$primary-to-tertiary-reverse;
}
.QuoteStyle2 {
	background: theme.$primary-to-tertiary-horizontal;
}
.QuoteStyle3 {
	background: theme.$primary-to-tertiary;
}
.QuoteStyle4 {
	background: theme.$pink-to-yellow-gradient;
}
.QuoteStyle5 {
	// background: theme.$orange-to-yellow-gradient; // too hard to read
	background: theme.$primary-to-tertiary-reverse;
}
.QuoteStyle6 {
	background: theme.$tertiary-to-quarternary-vertical-reverse;
}
.QuoteStyle7 {
	background: theme.$pink-to-yellow-gradient-reverse;
}
.QuoteStyle8 {
	background: theme.$tertiary-to-primary-gradient;
}
.QuoteStyle9 {
	background: theme.$tertiary-to-primary-reverse;
}
.QuoteStyle10 {
	background: theme.$tertiary-to-quarternary-horizontal;
}
.QuoteStyle11 {
	background: theme.$tertiary-to-quarternary;
}
.QuoteStyle12 {
	background: theme.$tertiary-to-quarternary-reverse;
}
.QuoteStyle13 {
	background: theme.$tertiary-to-quarternary-horizontal-reverse;
}
.QuoteStyle14 {
	background: theme.$tertiary-to-quarternary-vertical;
}

.Cursive {
	@include theme.cursive;
	font-size: 1.5em;
	width: 100%;
	padding-top: 20px;
	text-align: end;
}

.QuoteCollection {
	font-size: 12px;
	@include theme.flex-row-centered;
	position: relative;
	z-index: 1;
	overflow-y: hidden;
	height: min(
		90vh,
		700px
	); // 90vh to avoid quote being cropped on small screens / 700px to avoid too much blank space on wide screens
	// height: 20vh;
	// overflow-y: visible;
	// scroll-snap-type: x proximity;
	width: 100%;
	&::-webkit-scrollbar {
		display: none;
	}
	& > .QuoteAnimation {
		animation-name: slideIn;
		animation-iteration-count: 1;
		// animation-timing-function: ease-in-out;
		animation-fill-mode: initial;
		will-change: transform;
		visibility: visible;
	}
}

//
// Grows as it slides in
@keyframes slideIn {
	0% {
		transform: translate3d(250%) scale(0.8);
		opacity: 0;
		visibility: visible;
	}
	0% {
		transform: translate3d(0%) scale(1);
		opacity: 1;
		visibility: visible;
	}
	80% {
		transform: translate3d(0%) scale(1);
		opacity: 1;
		visibility: visible;
	}
	100% {
		transform: translate3d(250%) scale(0.8);
		opacity: 0;
		visibility: visible;
	}
}

.QuotePopupContainer {
	@include theme.flex-column-left;
	pointer-events: all;
	position: sticky;
	bottom: 0%;
	right: 0;
	margin-right: 2.5%;
	margin-left: auto;
	margin-bottom: 2.5%;
	z-index: 500;
	cursor: pointer;
	// background-image: linear-gradient(to top,black 0%, transparent 100%);
	// overflow-y: hidden;
	// height: min(90vh, 700px); // 90vh to avoid quote being cropped on small screens / 700px to avoid too much blank space on wide screens
	// height: 40vh;
	// overflow-y: visible;
	// scroll-snap-type: x proximity;

	&::-webkit-scrollbar {
		display: none;
	}
	> h2 {
		display: flex;
	}

	opacity: 0.9;
	transition: opacity 0.7s;
	&:hover {
		opacity: unset !important;
		// animation-play-state: paused; // Need to also bork the setInterval thingy, so this can't work in pure CSS
	}

	& > .QuoteAnimation {
		animation-name: riseUp;
		animation-iteration-count: infinite;
		// animation-timing-function: ease-in-out;
		animation-fill-mode: initial;
		will-change: transform;
		visibility: visible;
	}
}
//
// Grows as it slides in
@keyframes riseUp {
	0% {
		transform: translateY(100%);
		opacity: 0;
		visibility: visible;
	}
	7% {
		transform: translateY(0%);
		opacity: 0.9;
		visibility: visible;
	}
	93% {
		transform: translateY(0%);
		opacity: 0.9;
		visibility: visible;
	}
	100% {
		transform: translateY(100%);
		opacity: 0;
		visibility: visible;
	}
}

.close {
	position: absolute;
	top: -8%;
	left: -5%;
}
