@use "../theme.scss" as theme;

.QuestionnaireParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 430vh;
    @media (max-width: 600px) {
        height: 600vh;
    }
}

:global(.calendly-inline-widget) {
    width: 100vw !important;
    height: 100% !important;
    overflow-y: hidden !important;
}

.Content {
    // margin: max(10%, 50px) max(4%, 15px);
    font-size: 1.8rem;
    line-height: 2.9rem;
    text-align: center;
    max-width: 800px;

    & > strong {
        color: theme.$secondary-dark;
    }
    & > em {
        color: theme.$primary-dark;
        font-style: normal;
        @include theme.bold-Montserrat;
    }
}