@use "./../theme.scss" as theme;

$padding-bottom: max(25px, 3vh);

.FigureParent {
  @include theme.flex-column-centered;
  justify-content: flex-start;
  align-items: center; 
  @include theme.text;
  width: 28%;
  position: relative;
  @include theme.medium-screen {
    width: 70%;
  }
  margin: 5vh auto;
}

.PercentageText {
  @include theme.title-big;
  line-height: unset; // Not quite sure why this is getting set, but unsetting it fixes some style issues
  text-align: center;
  color: theme.$white;
  position: relative;
  left: 0.25ch;
  letter-spacing: 10px;
  text-shadow: -1px -3px 0 theme.$black, -2px -5px 0px #fafafa;
  padding-bottom: $padding-bottom;
}

.PercentSymbol {
  @include theme.title-medium;
}
// STYLING TO APPLY IF USING DIV INSTEAD OF <PROGRESS>
.PercentageContainer {
  border: 2px solid theme.$white;
  border-radius: theme.$progressBarRadius;
  width: 80%;
  height: max(25px, 2vh);
  z-index: 1;
}

// STYLING TO APPLY IF USING <DIV> INSTEAD OF <PROGRESS>
.PercentageBar {
  background-image: theme.$primary-to-tertiary-horizontal;
  border-radius: theme.$progressBarRadius;
  height: 100%;
  width: 1%; // replace with inline styling
  z-index: 0;
  transition: width 3s ease-in-out;
}

// STYLING TO APPLY IF USING <PROGRESS> INSTEAD OF <DIV>
// .PercentageProgress {
//   width: 100%; // replace with inline styling

//   border: 2px solid theme.$light-grey;
//   border-radius: theme.$progressBarRadius;

//   max-width: 800px;

//   &::-webkit-progress-bar {
//     background: transparent;
//   }
//   &::-webkit-progress-value {
//     // height: 1vh;
//     background: theme.$primary-to-tertiary-horizontal;
//     border-radius: 10px;
//     transition: width 3s ease;
//   }
// }

.Legend {
  @include theme.flex-column-centered; // to align content vertically
  height: 3rem; // fixed height so that all content is aligned
  // padding: 30px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
  margin: 1.1rem auto;
}

.AdditionalLegend {
  @include theme.flex-column-centered; // to align content vertically
  height: 3rem; // fixed height so that all content is aligned
  text-align: center;
  font-size: 1rem;
  // margin-bottom: $padding-bottom;
}
