@use '../theme.scss' as theme;

.Container {
	@include theme.flex-column-centered;
	min-height: 90vh;
	padding: 2vh 0;
	text-align: left;
}

.Grid {
	height: 100%;
	width: 100%;
	text-align: left;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(5, 1fr);
	grid-column-gap: 30px;
	grid-row-gap: 30px;
	max-height: 60vh;

	> a {
		@include theme.flex_column_centered;
		@include theme.title;
		font-size: min(7vw, 3rem);
		border-style: solid;
		border-width: 1px;
		border-color: white;
		border-radius: 10px;
		height: 100%;
		width: 100%;
		line-height: 100%;
		text-align: center;
		text-decoration: none;
		padding: 5px;
	}
	.icon {
		height: 10vmin;
		display: block;
		margin: 10px auto;
	}

	.Presentation {
		grid-area: 1 / 1 / 5 / 3;
		// Pink to blue on hover
		// Primary to tertiary
		&:hover {
			background-image: theme.$tertiary-to-primary-horizontal;
		}
	}
	.Catalogue {
		grid-area: 1 / 3 / 3 / 5;
		// yellow to orange on hover
		&:hover {
			background-image: linear-gradient(
				80deg,
				#ffd02e 5%,
				#fe672e 50%,
				#c7017c 90%
			);
		}
	}
	.Screencast {
		grid-area: 3 / 3 / 5 / 5;
		// Yellow to Blue on hover
		// secondary to tertiary
		&:hover {
			background-image: linear-gradient(
				70deg,
				#f8f864 7%,
				#28abc9 45%,
				#1560d3 90%
			);
		}
	}
	.RDV {
		// Blue to green
		// tertiary to quarternary
		grid-area: 5 / 1 / 6 / 5;
		&:hover {
			background-image: linear-gradient(
				60deg,
				#00cccccc 5%,
				#009933 48%,
				#00cc00 90%
			);
		}
	}
}

.Intro {
	max-width: 600px;
	width: 90vw;
	text-align: center;
	white-space: pre-wrap;
}
