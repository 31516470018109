@use './../theme.scss' as theme;

$padding-bottom: max(25px, 3vh);

.ConsentModal {
	@include theme.flex-column-centered;
	text-align: left;
	width: 70vw;
	max-width: 600px;
	position: sticky;
	bottom: 10vh;
	margin: 0 auto;
	padding: 20px;
	border-radius: 25px;
	background-color: rgb(114 156 172 / 0.23);
	z-index: 1000; // < 998 if you want it to be under the section headers
	backdrop-filter: blur(7px) contrast(0.6);
	& > * {
		width: 80%;
	}
}

.buttons {
	margin-top: -10px;
	@include theme.flex-row-centered;
	& > * {
		min-width: 100px;
		margin: 20px 30px;
	}
}
