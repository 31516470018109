@use './../theme.scss' as theme;

$fontSize: 2rem;
$transitionTime: 0.15s;
$playButtonSize: 5rem;

.Section {
	@include theme.flex-column-centered;
	max-width: theme.$contentMaxWidth;
	gap: 50px;
}

.Header {
	@include theme.flex-row-centered;
	justify-content: space-between;
	width: 100%;
	max-width: 1200px;
}

.Logo {
	width: max(30%, 30px);
	margin-right: 3%;
	@include theme.medium-screen {
		display: none;
	}
}

.EmptyLogo {
	width: max(30%, 30px);
	margin-right: 3%;
}

.TitleContainer {
	@include theme.flex-column-left;
	& > * {
		margin-bottom: max(2%, 10px);
	}
}

.Subtitle {
	@include theme.text;
	width: 100%;
	text-justify: inter-character; // not supported by many browsers yet, to implement later. In the meantime, sticking to left-align
	text-align: left;
	font-size: 1.2rem;
	line-height: 2rem;
	font-weight: 300;
}

.Error,
.Loading {
	position: relative;

	& > h3 {
		position: absolute;
		font-size: 5rem;
		z-index: 5;
		text-shadow: -0.2rem 0.2rem theme.$primary-dark,
			0.2rem -0.2rem theme.$tertiary-dark;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: fit-content; // to prevent line-breaks
	}
}

.ErrorLogo,
.LoadingLogo {
	position: relative;
	z-index: 1;
	left: 33%;
	top: -37%;
}

//////////////////////////////////////////
// VIDEO PLAYER
//////////////////////////////////////////

.VideoPlayerContainer {
	box-shadow: unset;
}

//////////////////////////////////////////
// EPISODES
//////////////////////////////////////////

.PlayerAndEpisodes {
	@include theme.flex-responsive-wrap;
	gap: 50px;
}

.EpisodesContainer {
	@include theme.flex-column-centered;
}

.VideoName {
	@include theme.title;
	font-size: 2.5rem;
	line-height: 2.5rem;
	text-align: left;
}

.EmbeddedEpisode {
	@include theme.flex-responsive-wrap;
	align-items: center;
	width: 100%;
	transition: all $transitionTime ease-in-out;
	transform: scale(0.95);
	opacity: 0.8;

	&:hover,
	&.Selected {
		transform: scale(1);
		cursor: pointer;
		opacity: 1;

		.VideoName {
			text-decoration: underline;
		}

		.Thumbnail,
		.VideoInfo {
			transform: scale(1);
		}
	}

	.ThumbnailContainer {
		align-self: center;
		width: 400px;
		height: 400px;
		max-width: 90vw;
		max-height: 90vh;
		position: relative;

		.PlayButton {
			width: $playButtonSize;
			height: $playButtonSize;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5)) opacity(0.8);
			transition: all 0.2s ease-in-out;
		}
	}

	.Thumbnail {
		transition: all 0.2s ease-in-out;
		max-width: inherit;
		max-height: inherit;
		width: inherit;
		height: inherit;
		object-fit: cover;
		border-radius: 20px;
		position: relative;
		transform: scale(0.9);
	}

	.VideoInfo {
		@include theme.flex-column-left;
		justify-content: flex-start;
		padding: 30px 3vmin;
		line-height: 1.5rem;
		width: 100%;
		max-width: 500px;
		transform: scale(0.9);
		transition: all $transitionTime ease-in-out;

		& > * {
			padding: 1vmin;
		}

		.Interviewee {
			@include theme.title;
			font-size: 1.5rem;
			font-weight: 700;
			line-height: 1.8rem;
			text-align: left;
		}

		.VideoTheme {
			@include theme.text;
			text-align: left;
		}
	}
}
