@use "../theme.scss" as theme;

.CultureGV {
    margin: max(5%, 40px) max(5%, 25px) 0;
    font-size: 1.2rem;
    font-weight: 300;
}

.Content {
    margin: max(10%, 50px) max(4%, 15px);
    text-align: left;
    
    & > em {
        @include theme.bold-Montserrat;
    }
    & > strong {
        @include theme.bold-Montserrat;
        font-size: 1.3rem;
    }
}