@use "../theme.scss" as theme;

.Page {
	@include theme.text;
	@include theme.flex-column;
	color: theme.$white;

	// Causes sticky not work
	// overflow-x: hidden;
}

.BigSectionContainer {
	@include theme.flex-column-centered;
	max-width: 1600px;
	min-width: 100%;
	height: 100%;
	min-height: 100vh;
}

h3 {
	@include theme.title;
}

h4 {
	@include theme.text;
	font-size: 1.875rem;
	line-height: 40px;
	font-weight: 700;
	margin: 0 auto;
}

h5 {
	@include theme.section-subtitle;
}
