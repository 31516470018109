@use "./../theme.scss" as theme;

.BtnCta {
  @include theme.buttons;
  background: theme.$pink-to-yellow-gradient;
  border-radius: 400px;
  text-align: center;
  transition: opacity;
  animation: ease-in-out 1s;
  color: white;
  white-space: nowrap;
  margin: theme.$formfieldsAnButtonsMargin;
  @include theme.highlight-and-grow-on-hover(theme.$white);
  &.Secondary {
    color: theme.$primary-light;
    background: theme.$white;
  }

  &.Tertiary {
    color: theme.$white;
    background: transparent;
    border: 1px solid theme.$white;
  }
  
  &.Transparent {
    background: transparent;
  }

  &.Navbar {
    background: transparent;
    box-shadow: none;
  }
  
  &.Disabled {
    opacity: 0.2;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 2000px;
    &:hover {
      pointer-events: none;
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    }
  }
}


@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}