@use "../theme.scss" as theme;

.Container {
    margin: max(5%, 40px) max(5%, 25px);
    max-width: unset;
    width: 90%;
}

.Content {
    margin: max(10%, 50px) max(4%, 15px);
    font-size: 1.8rem;
    line-height: 2.9rem;
    text-align: left;
    & > strong {
        color: theme.$secondary-dark;
    }
    & > em {
        color: theme.$primary-dark;
        font-style: normal;
        @include theme.bold-Montserrat;
    }
}