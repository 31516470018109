$extraLargeScreenBreakpoint: 1600px;
$largeScreenBreakpoint: 1200px;
$mediumScreenBreakpoint: 540px;
$smallScreenBreakpoint: 375px;

@mixin extra-large-screen {
	// wide desktop
	@media (min-width: $extraLargeScreenBreakpoint) {
		@content;
	}
}
@mixin large-screen {
	// desktop
	@media (max-width: $extraLargeScreenBreakpoint - 1px) {
		@content;
	}
}
@mixin medium-screen {
	// tablets
	@media (max-width: $largeScreenBreakpoint - 1px) {
		@content;
	}
}
@mixin small-screen {
	// mobile
	@media (max-width: $mediumScreenBreakpoint - 1px) {
		@content;
	}
}
@mixin extra-small-screen {
	// small mobile
	@media (max-width: $smallScreenBreakpoint - 1px) {
		@content;
	}
}

html {
	@include extra-large-screen {
		font-size: 18px;
	}
	@include large-screen {
		font-size: 16px;
	}
	@include medium-screen {
		font-size: 14px;
	}
	@include small-screen {
		font-size: 12px;
	}
	@include extra-small-screen {
		font-size: 10px;
	}
}

// Colors
$transparency-high: 0.6;
$transparency-med: 0.75;
$transparency-low: 0.9;

/* Main colors */
$black: rgba(51, 51, 51, 1);
$white: white;
$transparent-white: (255, 255, 255, 0.6);
$grey: rgba(119, 119, 119, 1);
$red: rgba(255, 50, 74, 1);
$royal-blue: rgba(78, 96, 229, 1);
$semi-transparent-grey: rgba(0, 0, 0, 0.75);
$light-grey: rgba(0, 0, 0, 0.25);
$lighter-grey: rgba(0, 0, 0, 0.1);
$transparentGrey: rgba(0, 0, 0, 0.15);

// NOTE: colors with "DA ok" next to them have be double-checked, are compliant with DA.
// todo: check other colors and update them with the right colors

/* Primary / pinks */
$primary: rgb(199, 1, 124); // DA ok
$primary-light: rgba(253, 0, 132, 1);
$primary-dark: rgb(204, 13, 139); // DA ok

/* Secondary / oranges */
$secondary: rgb(254, 103, 46); // DA ok
$secondary-light: rgb(255, 172, 67); // DA ok
$secondary-dark: rgba(253, 91, 0, 1);

/* Yellow */
$yellow: rgb(255, 208, 46);

/* tertiary / blues */
$tertiary-light: rgba(23, 105, 210, 1);
$tertiary-dark: rgba(78, 96, 229, 1);
$tertiary-darker: rgba(30, 55, 241, 1); /* used for figures gradient */
$tertiary-darkblue: rgba(25, 33, 90, 1);

/* Quarternary / greens */
$quarternary-light: rgba(53, 222, 121, 1);
// $quarternary-dark: rgba(0,0,0, 1);

/* Gradients */

/* Main gradients */

/* Pink to orange (used in gv episodes) */
$pink-to-yellow-gradient: linear-gradient(
	90deg,
	$primary-dark 0%,
	$secondary 100%
);

/* Orange to yellow */
$orange-to-yellow-gradient: linear-gradient(90deg, $secondary 0%, $yellow 100%);

/* Pink to orange to yellow */
$pink-to-yellow-gradient: linear-gradient(
	90deg,
	$primary 0%,
	$secondary 70%,
	$yellow 100%
);
$pink-to-yellow-gradient-reverse: linear-gradient(
	90deg,
	$yellow 0%,
	$secondary 30%,
	$primary 100%
);

$yellow-to-blue-gradient-reverse: linear-gradient(
	90deg,
	$yellow 0%,
	$tertiary-light 30%,
	$tertiary-dark 100%
);

/* Blue/pink */
$tertiary-to-primary-gradient: linear-gradient(
	0deg,
	$primary-dark 0%,
	$tertiary-dark 100%
);
$tertiary-to-primary-reverse: linear-gradient(
	180deg,
	$primary-dark 0%,
	$tertiary-dark 100%
);
$tertiary-to-primary-horizontal: linear-gradient(
	90deg,
	$primary 0%,
	$tertiary-darker 100%
);
$tertiary-to-primary-horizontal-reverse: linear-gradient(
	90deg,
	$tertiary-darker 0%,
	$primary 100%
);

/* Primary Gradients */
$primary-gradient: linear-gradient(90deg, $primary-light 0%, $primary 100%);

/* Secondary Gradients */
$secondary-gradient: linear-gradient(
	70deg,
	$secondary-dark 0%,
	$secondary-dark 100%
);

/* tertiary Gradients */
$primary-to-tertiary: linear-gradient(
	166deg,
	$primary-dark 0%,
	$tertiary-dark 100%
);

$primary-to-tertiary-reverse: linear-gradient(
	200deg,
	$primary-dark 0%,
	$tertiary-dark 100%
);

$primary-to-tertiary-horizontal: linear-gradient(
	70deg,
	$primary-dark 0%,
	$tertiary-darker 100%
);
$tertiary-gradient: linear-gradient(
	70deg,
	$tertiary-light 0%,
	$tertiary-darkblue 100%
);
$tertiary-gradient-reverse: linear-gradient(
	230deg,
	$tertiary-light 0%,
	$tertiary-darkblue 100%
);

/* Quarternary Gradients */
$tertiary-to-quarternary: linear-gradient(
	220deg,
	$tertiary-dark 0%,
	$quarternary-light 100%
);
$tertiary-to-quarternary-reverse: linear-gradient(
	30deg,
	$tertiary-dark 0%,
	$quarternary-light 100%
);
$tertiary-to-quarternary-horizontal: linear-gradient(
	166.66deg,
	$tertiary-dark 0%,
	$quarternary-light 100%
);
$tertiary-to-quarternary-horizontal-reverse: linear-gradient(
	290deg,
	$tertiary-dark 0%,
	$quarternary-light 100%
);

$tertiary-to-quarternary-vertical: linear-gradient(
	270deg,
	$tertiary-dark 0%,
	$quarternary-light 100%
);
$tertiary-to-quarternary-vertical-reverse: linear-gradient(
	90deg,
	$tertiary-dark 0%,
	$quarternary-light 100%
);

$shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.25);

// Sizes
$navBarHeight: 11vh;

// Backgrounds
@function background-alpha($alpha: 1) {
	@return rgba(9, 11, 26, $alpha);
}
$background-darkblue: background-alpha(1);
$backgroundGradient: linear-gradient($background-darkblue 0%, #4e60e5 200%);

$cardHeaderBackground: $secondary-gradient;
$cardContentBackground: $white;
$NavMobileBackground: $cardHeaderBackground;

// Card
$cardCornerRadius: 8px;
$cardshadow: $shadow;

// CTA
$ctaShadow: box-shadow(0px 4px 4px pink);

@mixin font($font-family, $font-file, $backup-fonts: sans-serif) {
	@font-face {
		font-family: $font-family, $backup-fonts;
		src: local($font-family);
		src: url($font-file + '.eot');
		src: url($font-file + '.eot?#iefix') format('embedded-opentype'),
			url($font-file + '.woff') format('woff'),
			url($font-file + '.ttf') format('truetype'),
			url($font-file + '.svg#' + $font-family) format('svg');
		font-weight: normal;
		font-style: normal;
	}
}

@font-face {
	font-family: 'Bernadette';
	src: local('Bernadette'),
		url('./static/fonts/Bernadette.ttf') format('truetype'),
		url('./static/fonts/Bernadette.woff') format('woff'),
		url('./static/fonts/Bernadette.woff2') format('woff2'),
		url('./static/fonts/Bernadette.eot') format('eot');
	font-weight: bold;
}

@include font('Bernadette', 'static/fonts/Bernadette', cursive);

@font-face {
	font-family: 'Bebas Neue';
	src: local('Bebas Neue'),
		url('./static/fonts/BebasNeueRegular.ttf') format('truetype'),
		url('./static/fonts/Bernadette.woff') format('woff'),
		url('./static/fonts/Bernadette.woff2') format('woff2'),
		url('./static/fonts/Bernadette.eot') format('embedded-opentype'),
		url('./static/fonts/Bernadette.svg') format('svg');
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;1,100;1,200;1,400;1,600&display=swap');

@font-face {
	src: local('Montserrat');
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
}

@include font('Bernadette', 'static/fonts/Bernadette', cursive);

@include font(
	'Bebas Neue',
	'static/fonts/BebasNeueRegular',
	'Impact, sans-serif'
);

@mixin bold-Montserrat {
	font-weight: 600;
}

// Typographies
@mixin title {
	$size: 3.4375rem;
	font-family: 'Bebas Neue';
	font-weight: 400;
	font-size: $size;
	text-align: center;
	line-height: calc(#{$size} + 4px);
}

@mixin title-big {
	@include title;
	font-size: 12rem;
}
@mixin title-medium {
	@include title;
	font-size: 9rem;
}
@mixin title-small {
	@include title;
	font-size: 6rem;
}

@mixin text {
	font-family: 'Montserrat', sans-serif;
}

@mixin italic-subtitle {
	@include text;
	font-weight: 500;
	font-style: italic;
	font-size: 2.1875rem;
	line-height: 70px;
	text-align: center;
}

@mixin bold-subtitle {
	@include text;
	font-weight: 700;
	font-size: 2.1875rem;
	line-height: 70px;
	text-align: center;
}

@mixin section-subtitle {
	@include text;
	font-weight: 700;
	font-size: 2.1875rem;
	line-height: 40px;
	text-align: left;
}

$buttonsPadding: 0.625rem 2.1875rem;

@mixin buttons {
	@include text;
	font-size: 1rem;
	font-weight: 700;
	line-height: 18px;
	padding: $buttonsPadding;
}

@mixin cursive {
	font-family: 'Bernadette', cursive;
}

/* GRADIENT TEXT */

@mixin gradient-text {
	-webkit-background-clip: text;
	// -webkit-box-decoration-break: clone;
	-webkit-text-fill-color: transparent;
	text-shadow: 0px 0px #00000000; // needed for Safari, otherwise gradient won't be displayed
}

@mixin gradient-text-color($gradient) {
	background: $gradient; // important: background color needs to be BEFORE the background-clip property :)
	@include gradient-text;
}

@mixin bold-gradient-text {
	@include gradient-text;
	@include bold-Montserrat;
	font-size: 2.8rem;
	text-transform: uppercase;
}

@mixin gradient-text-cursive {
	@include cursive;
	font-size: 6.25rem;
	font-weight: 400;
	text-align: center;
	margin: 0.5rem;
	padding: 0.5rem;
}

@mixin hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

// FLEXBOX MIXINS //
@mixin flex-column {
	display: flex;
	flex-direction: column;
}

@mixin flex-row {
	display: flex;
	flex-direction: row;
}

@mixin flex-column-centered {
	@include flex-column;
	align-items: center;
	justify-content: center;
}

@mixin flex-column-left {
	@include flex-column;
	align-items: flex-start;
	justify-content: center;
}

@mixin flex-row-left {
	@include flex-row;
	align-items: center;
	justify-content: flex-start;
}

@mixin flex-row-centered {
	@include flex-row;
	align-items: center;
	justify-content: center;
}

@mixin flex-responsive-wrap {
	@include flex-row-centered;
	flex-wrap: wrap;
}

@mixin highlight-and-grow-on-hover($color: $secondary-dark) {
	transition: filter 0.5s, transform 0.25s;
	&:hover {
		transform: scale(1.05);
		filter: drop-shadow(0 0 0.5rem $color);
	}
}

html {
	color: $white;
	user-select: none;
	margin: 0;
	padding: 0;
}

$containerMaxWidth: 100%;
$contentMaxWidth: 90vw;
$formfieldsAnButtonsMargin: 10px 5px;
$formfieldsAndButtonsWidth: min(18rem, 50vw);
$progressBarRadius: 20px;

body {
	@include flex-column;
	@include text;
	overflow-x: hidden;
	min-width: 100vw;
	max-width: 100vw;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	background: $backgroundGradient;
}

section {
	max-width: min(
		1200px,
		95vw
	); // on big screens, max-width is 1200px, while on smaller screens, it will take most of the space (95vw)
	height: 100%;

	.FlexColumnContainer {
		margin: 0 25px;
		scroll-snap-align: start;
	}
}

a {
	color: $white;
	text-decoration: underline;
	&:hover &:not(.BtnCta) {
		// :not(.BtnCta) is used to prevent gradient text if BtnCta is used as a link, so that text stays white
		@include gradient-text-color($orange-to-yellow-gradient);
	}
}

h1 {
	@include title;
	align-self: center;
	margin: max(5%, 40px) auto;
	width: 100%;
	text-align: center;
}

legend {
	font-size: 0.8rem;
}

.Paragraph {
	font-size: 1.3rem;
	text-align: left;
}

.FlexColumnContainer {
	// used to create a single parent displayed flex/column/centered
	@include flex-column-centered;
	justify-content: space-between;
	text-align: center;
}

@mixin StickyContainer($top: 0, $z: 10) {
	position: sticky;
	top: $top;
	z-index: $z;
}

$mask-image: linear-gradient(
	180deg,
	transparent 5%,
	black 95%,
	transparent 100%
);

.Mobile {
	display: none;
}

@include medium-screen {
	.Desktop {
		display: none;
	}
	.Mobile {
		display: block;
	}
}

.VideoPlayerContainer {
	max-width: 1600px;
	margin: 5vh auto;
	height: 100%;
	width: 100%;
	& > div {
		height: 100%;
		width: 100%;
	}
	& iframe {
		margin: 50px auto;
		background-color: transparent;
		animation: fade-shadow-in ease-in-out 6s;
		animation-fill-mode: forwards;
		border-radius: 20px;
	}
	@keyframes fade-shadow-in {
		0% {
			background-color: transparent;
			box-shadow: 0px 0px 0px 0px blueviolet;
		}
		100% {
			background-color: blueviolet;
			box-shadow: 0px 0px 20px 4px blueviolet;
		}
	}
}
