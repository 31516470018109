@use "../theme.scss" as theme;
@import "../components/Forms.module.scss";

.FormPageParent {
    @include theme.flex-column-centered;
    margin: max(5%, 50px) auto;
    max-width: theme.$contentMaxWidth;
}

.DescriptionText {
	width: min(36rem, 60vw);
    margin: 2vh 1vw;
    text-align: center;
    a, b {
        @include theme.title;
    }
    em {
        @include theme.gradient-text-color(
            theme.$secondary-gradient
        )
    }
}

.WhitePaper {
	min-height: 100vh;
	width: 100%;
}

.Container {
    @include theme.flex-column-centered;
    justify-content: space-around;
	@include theme.text;
	font-size: 1.5rem;
	line-height: 2rem;
	min-height: 100vh;
    padding: 10% 0;
}

.Content {
    margin: 0 5vw;
    max-width: 1200px;
}

.Section {
    padding-bottom: 2rem;
    p {
        text-align: left;
    }

    h4 { // section title
        @include theme.title;
        padding: 5%;
    }
}

