@use "./../theme.scss" as theme;
@use "src/components/BtnCta.module.scss" as BtnCta;

.FormField {
   background: transparent;
   border: 2px solid #FFFFFF;
   box-sizing: border-box;
   border-radius: 400px;
   padding: 10px 20px;
   min-width: 200px;
   margin: theme.$formfieldsAnButtonsMargin;

   transition: border-color 0.8s;
   
	@include theme.bold-Montserrat;
   color: theme.$white;
   
   &::placeholder {
      color: #ffffffcc;
      font-style: italic;
   }
}

.FormBody {
	@include theme.flex-column-centered;
	max-width: theme.$containerMaxWidth;
}

.Inputs {
	// @include theme.flex-row-left;
	flex-wrap: wrap;
	margin: theme.$formfieldsAnButtonsMargin;
	max-width: theme.$contentMaxWidth;
	@include theme.flex-column-centered;
	@include theme.medium-screen {
	}
}

.VerticalInputs {
	@include theme.flex-column-centered;

	.FormField {
		width: theme.$formfieldsAndButtonsWidth;
	}
}

.ChooseTheme {
	@include theme.flex-column-centered;
	flex-wrap: wrap;
}

.Error {
	border-color: theme.$red;
}

.ErrorMessage {
	margin: 15px;
	width: 100%;
	max-width: theme.$contentMaxWidth;
}

.SubmitButton {
	border: none; // overrides browser default

	.VerticalInputs { 
		width: theme.$formfieldsAndButtonsWidth; // give inputs the same width as submit btn when form is displayed vertically
	}
}