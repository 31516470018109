@use "./../theme.scss" as theme;

$fontSize: 2rem;
$transitionTime: 0.15s;
$playButtonSize: 50px;

.Section {
    @include theme.flex-column-centered;
    max-width: theme.$contentMaxWidth;
    gap: 50px;
}

.Header {
    @include theme.flex-row-centered;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
}

.Logo {
    width: max(30%, 30px);
    margin-right: 3%;
    @include theme.medium-screen {
        display: none;
    }
}

.EmptyLogo {
    width: max(30%, 30px);
    margin-right: 3%;
}

.TitleContainer {
    @include theme.flex-column-left;
    & > * {
        margin-bottom: max(2%, 10px);
    }
}

.Subtitle {
    @include theme.text;
    width: 100%;
    text-justify: inter-character; // not supported by many browsers yet, to implement later. In the meantime, sticking to left-align
    text-align: left;
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 300;
}

.Error, .Loading {
    position: relative;
    
    & > h3 {
        position: absolute;
        font-size: 5rem;
        z-index: 5;
        text-shadow: -0.2rem 0.2rem theme.$primary-dark, 0.2rem -0.2rem theme.$tertiary-dark;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content; // to prevent line-breaks
    }
}

.ErrorLogo, .LoadingLogo {
    position: relative;
    z-index: 1;
    left: 33%;
    top: -37%;
}


//////////////////////////////////////////
// VIDEO PLAYER
//////////////////////////////////////////

.VideoPlayer {
    width: max(440px, 40vmin);
    height: max(785px, 71vmin);
    max-width: 95vw;
    max-height: 90vh;
}


//////////////////////////////////////////
// EPISODES
//////////////////////////////////////////

.PlayerAndEpisodes {
    @include theme.flex-responsive-wrap;
}

.EpisodesContainer {
    @include theme.flex-column-centered;
}

.EmbeddedEpisode {
    @include theme.flex-responsive-wrap;
    align-items: center;
    width: 100%;
    transition: all $transitionTime ease-in-out;
    transform: scale(0.9);
    
    &:hover {
        transform: scale(1);
        cursor: pointer;

        .VideoName {
            text-decoration: underline;
        }

        .Thumbnail,
        .VideoInfo {
            transform: scale(1);
        }
    }
    
    .ThumbnailContainer {
        align-self: center;
        width: 200px;
        height: 200px;
        max-width: 90vw;
        max-height: 90vh;
        position: relative;
        
        .PlayButton {
            width: $playButtonSize;
            height: $playButtonSize;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            filter: opacity(0.8);
            transition: all 0.2s ease-in-out;
        }
    }
    
    .Thumbnail {
        transition: all 0.2s ease-in-out;
        max-width: inherit;
        max-height: inherit;
        width: inherit;
        height: inherit;
        object-fit: cover;
        border-radius: 20px;
        position: relative;
        transform: scale(0.9);
    }

    .VideoInfo {
        @include theme.flex-column-left;
        justify-content: flex-start;
        padding: 30px 3vmin;
        line-height: 1.5rem;
        width: 100%;
        max-width: 400px;
        transform: scale(0.9);
        transition: all $transitionTime ease-in-out;
        
        & > * {
            padding: 1vmin;
        }
        
        .VideoName {
            @include theme.title;
            font-size: 2.5rem;
            line-height: 2.5rem;
            text-align: left;
        }

        .Interviewee {
            @include theme.title;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.8rem;
            text-align: left;   
        }

        .VideoTheme {
            @include theme.text;
            text-align: left;
        }
    }
}


//////////////////////////////////////////
//////////////////////////////////////////
// CYCLES
//////////////////////////////////////////
//////////////////////////////////////////

.Description {
    margin: 0 20px;
}

//////////////////////////////////////////
// FOCUS MANAGEMENT / COLLECTIF
//////////////////////////////////////////

.CyclesCollectifContainer {
    @include theme.flex-column-centered;
    width: min(600px, 95vw);
    margin: 0 auto;
}

.OneCycleCollectifContainer {
    @include theme.flex-row-centered;
    align-items: flex-start;
    width: 100%;
    transform-origin: center;
    transform: scale(0.9);
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1);
        // @include theme.gradient-text-color(theme.$orange-to-yellow-gradient); // this does not work in Safari, makes the element disappear on hover. Removing it for now, hoping to reimplement it at some point.
    }
    
    .CycleCollectifTitle {
        @include theme.title;
        @include theme.flex-column-left;
        width: 80%; // has to be 100% together with CycleCollectifNbEpisodes width
        max-width: 350px;
        font-size: $fontSize;
        transition: all 0.04s ease-in-out;
        align-items: flex-start;
        padding-right: 2vmin;
        text-align: left; // necessary for longer titles
        max-height: fit-content;
    }
    
    .CycleCollectifNbCycles {
        width: 20%; // has to be 100% together with CycleCollectifTitle width
        @include theme.title;
        font-size: 1.5rem;
        font-weight: 300;
        align-items: flex-end;
        justify-content: flex-end;
        text-align: justify;
    }
}


//////////////////////////////////////////
// FOCUS INDIVIDUS / COLLABORATEURICES
//////////////////////////////////////////

.CyclesIndividuContainer {
    max-width: min(700px, 90vw);
    @include theme.title;
    font-size: $fontSize;
    text-align: left;
    letter-spacing: 0.3rem;
    line-height:4rem;
    @include theme.flex-responsive-wrap;
}

.OneCycleIndividu {
    transform: scale(0.9);
    transition: all 0.2s ease-in-out;
    padding: 0 5px;
    &:hover {
        transform: scale(1);
        // @include theme.gradient-text-color(theme.$orange-to-yellow-gradient) // for some reason, this is working EVEN on Safari. However, the same behavior doesn't work for the "series", so we're commenting this out for consistency purposes for now.
    }
}

.Slash {
    transform: scale(0.9);
}